import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as dat from 'lil-gui'
import { ClampToEdgeWrapping, CylinderGeometry, Material, MeshStandardMaterial, Raycaster } from 'three'




/**
 * Loaders
 */

 const dracoLoader = new DRACOLoader()
 dracoLoader.setDecoderPath('/draco/')
 
 const gltfLoader = new GLTFLoader()
 gltfLoader.setDRACOLoader(dracoLoader)

 const loadingManager = new THREE.LoadingManager()
 const textureLoader = new THREE.TextureLoader(loadingManager)

 const cubeTextureLoader = new THREE.CubeTextureLoader()
 



 /**
* Base
*/
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()





/**
 * Lights
 */

const directionalLight = new THREE.DirectionalLight('#ffeaaba', 3)
directionalLight.position.set(0.25,3,-2.25)
scene.add(directionalLight)

/**
 * Update All Materials
 */

const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh)
        {
            child.material.envMap = hdri
            child.material.envMapIntensity = 1.5
        }
    })
}




/**
 * HDRI
 */

const hdri = cubeTextureLoader.load([
    '/hdri/px.png',
    '/hdri/nx.png',
    '/hdri/py.png',
    '/hdri/ny.png',
    '/hdri/pz.png',
    '/hdri/nz.png'
])
hdri.encoding = THREE.sRGBEncoding




const raycaster = new Raycaster()


/**
 * BLENDER
 */
 
 let mixer = null
 
 gltfLoader.load(
     '/models/blender.glb',
     (blender) =>
     {  

    //Animate

    const tick = () =>
{

    //Update Objects
    blender.scene.rotation.y += 0.005


    // Update controls
    controls.update()

    for(const point of pointsBlender)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonBlender = document.querySelector('.backButtonBlender')
       const buttonBlender = document.querySelector('.pointBlender')
       const titleBlender = document.querySelector("#titleBlender")
       const descriptionBlender = document.querySelector("#descriptionBlender")
       const lessInfoBlender = document.querySelector('.lessInfoBlender')
       const moreInfoBlender = document.querySelector('.moreInfoBlender')
       

       function newCenterBlender()
        {
        controls.target.set(7,3.6,3)
        camera.far = 5
        camera.updateProjectionMatrix();
        backButtonBlender.classList.add('visible')
        titleBlender.classList.add('visible')
        descriptionBlender.classList.add('visible')
        lessInfoBlender.classList.add('visible')
        }


        function goBackBlender()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(9.68,4.97,4.076)
        camera.updateProjectionMatrix();
        titleBlender.classList.remove('visible')
        descriptionBlender.classList.remove('visible')
        lessInfoBlender.classList.remove('visible')
        moreInfoBlender.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX1= screenPosition.x * sizes.width * 0.5
       const translateY1 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX1}px)  translateY(${translateY1}px)`


       buttonBlender.addEventListener('click', function() {
       newCenterBlender()
       point.element.classList.add('inside')
       })


       backButtonBlender.addEventListener('click', function() {
       goBackBlender()
       point.element.classList.remove('inside')
       backButtonBlender.classList.remove('visible')
     })


       lessInfoBlender.addEventListener('click', function(){
        descriptionBlender.classList.remove('visible')
        titleBlender.classList.remove('visible')
        moreInfoBlender.classList.add('visible')
       })

       moreInfoBlender.addEventListener('click', function(){
        descriptionBlender.classList.add('visible')
        titleBlender.classList.add('visible')
        lessInfoBlender.classList.add('visible')
        moreInfoBlender.classList.remove('visible')
       })
       
       
   }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


        blender.scene.scale.set(.19,.19,.19)
        blender.scene.position.set(7,3,3)
         scene.add(blender.scene)

         updateAllMaterials()
     }
)




gltfLoader.load(
    '/models/hold.glb',
    (hold) =>
    {  

   //Animate

   const tick2 = () =>
{

   //Update Objects
   hold.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsPhone)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonPhone = document.querySelector('.backButtonPhone')
       const buttonPhone = document.querySelector('.pointPhone')
       const titlePhone = document.querySelector("#titlePhone")
       const descriptionPhone = document.querySelector("#descriptionPhone")
       const lessInfoPhone = document.querySelector('.lessInfoPhone')
       const moreInfoPhone = document.querySelector('.moreInfoPhone')



       function newCenterPhone()
        {
        controls.target.set(5.4,3.5,5.3)
        camera.far = 6
        camera.updateProjectionMatrix();
        backButtonPhone.classList.add('visible')
        titlePhone.classList.add('visible')
        descriptionPhone.classList.add('visible')
        lessInfoPhone.classList.add('visible')
        }


        function goBackPhone()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(7.5,4.97,7.31)
        camera.updateProjectionMatrix();
        titlePhone.classList.remove('visible')
        descriptionPhone.classList.remove('visible')
        lessInfoPhone.classList.remove('visible')
        moreInfoPhone.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX2 = screenPosition.x * sizes.width * 0.5
       const translateY2 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX2}px)  translateY(${translateY2}px)`


       buttonPhone.addEventListener('click', function() {
       newCenterPhone()
       point.element.classList.add('inside')
       })


       backButtonPhone.addEventListener('click', function() {
       goBackPhone()
       point.element.classList.remove('inside')
       backButtonPhone.classList.remove('visible')
     })
       
        lessInfoPhone.addEventListener('click', function(){
        descriptionPhone.classList.remove('visible')
        titlePhone.classList.remove('visible')
        moreInfoPhone.classList.add('visible')
       })

        moreInfoPhone.addEventListener('click', function(){
        descriptionPhone.classList.add('visible')
        titlePhone.classList.add('visible')
        lessInfoPhone.classList.add('visible')
        moreInfoPhone.classList.remove('visible')
       })
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick2)
}

tick2()


        hold.scene.scale.set(.2,.2,.2)
        hold.scene.position.set(5.4,3.1,5.3)
        scene.add(hold.scene)

        updateAllMaterials()
    }
)





gltfLoader.load(
    '/models/laundry.glb',
    (laundry) =>
    {  

   //Animate

   const tick3 = () =>
{

   //Update Objects
   laundry.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsLaundry)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonLaundry = document.querySelector('.backButtonLaundry')
       const buttonLaundry = document.querySelector('.pointLaundry')
       const titleLaundry = document.querySelector("#titleLaundry")
       const descriptionLaundry = document.querySelector("#descriptionLaundry")
       const lessInfoLaundry = document.querySelector('.lessInfoLaundry')
       const moreInfoLaundry = document.querySelector('.moreInfoLaundry')



       function newCenterLaundry()
        {
        controls.target.set(7,3.6,-3)
        camera.far = 4
        camera.updateProjectionMatrix();
        backButtonLaundry.classList.add('visible')
        titleLaundry.classList.add('visible')
        descriptionLaundry.classList.add('visible')
        lessInfoLaundry.classList.add('visible')
        }


        function goBackLaundry()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(9.71,4.97,-4.06)
        camera.updateProjectionMatrix();
        titleLaundry.classList.remove('visible')
        descriptionLaundry.classList.remove('visible')
        lessInfoLaundry.classList.remove('visible')
        moreInfoLaundry.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX3 = screenPosition.x * sizes.width * 0.5
       const translateY3 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX3}px)  translateY(${translateY3}px)`


       buttonLaundry.addEventListener('click', function() {
       newCenterLaundry()
       point.element.classList.add('inside')
       })


       backButtonLaundry.addEventListener('click', function() {
       goBackLaundry()
       point.element.classList.remove('inside')
       backButtonLaundry.classList.remove('visible')
     })

     lessInfoLaundry.addEventListener('click', function(){
        descriptionLaundry.classList.remove('visible')
        titleLaundry.classList.remove('visible')
        moreInfoLaundry.classList.add('visible')
       })

        moreInfoLaundry.addEventListener('click', function(){
        descriptionLaundry.classList.add('visible')
        titleLaundry.classList.add('visible')
        lessInfoLaundry.classList.add('visible')
        moreInfoLaundry.classList.remove('visible')
       })
       
       
   }




   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick3)
}

tick3()


        laundry.scene.scale.set(.21,.21,.21)
        laundry.scene.position.set(7,3,-3)
        scene.add(laundry.scene)

        updateAllMaterials()
    }
)




gltfLoader.load(
    '/models/trashcan.glb',
    (trash) =>
    {  

   //Animate

   const tick4 = () =>
{

   //Update Objects
   trash.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsTrash)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonTrash = document.querySelector('.backButtonTrash')
       const buttonTrash = document.querySelector('.pointTrash')
       const titleTrash = document.querySelector("#titleTrash")
       const descriptionTrash = document.querySelector("#descriptionTrash")
       const lessInfoTrash = document.querySelector('.lessInfoTrash')
       const moreInfoTrash = document.querySelector('.moreInfoTrash')



       function newCenterTrash()
        {
        controls.target.set(5.2,3.5,-5.5)
        camera.far = 4
        camera.updateProjectionMatrix();
        backButtonTrash.classList.add('visible')
        titleTrash.classList.add('visible')
        descriptionTrash.classList.add('visible')
        lessInfoTrash.classList.add('visible')
        }


        function goBackTrash()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(7.266,4.97,-7.64)
        camera.updateProjectionMatrix();
        titleTrash.classList.remove('visible')
        descriptionTrash.classList.remove('visible')
        lessInfoTrash.classList.remove('visible')
        moreInfoTrash.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX4 = screenPosition.x * sizes.width * 0.5
       const translateY4 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX4}px)  translateY(${translateY4}px)`


       buttonTrash.addEventListener('click', function() {
       newCenterTrash()
       point.element.classList.add('inside')
       })


       backButtonTrash.addEventListener('click', function() {
       goBackTrash()
       point.element.classList.remove('inside')
       backButtonTrash.classList.remove('visible')
     })

     lessInfoTrash.addEventListener('click', function(){
        descriptionTrash.classList.remove('visible')
        titleTrash.classList.remove('visible')
        moreInfoTrash.classList.add('visible')
       })

        moreInfoTrash.addEventListener('click', function(){
        descriptionTrash.classList.add('visible')
        titleTrash.classList.add('visible')
        lessInfoTrash.classList.add('visible')
        moreInfoTrash.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick4)
}

tick4()


        trash.scene.scale.set(.23,.23,.23)
        trash.scene.position.set(5.2,2.95,-5.5)
        scene.add(trash.scene)

        updateAllMaterials()
    }
)




gltfLoader.load(
    '/models/vacuum.glb',
    (vacuum) =>
    {  

   //Animate

   const tick5 = () =>
{

   //Update Objects
   vacuum.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsVacuum)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonVacuum = document.querySelector('.backButtonVacuum')
       const buttonVacuum = document.querySelector('.pointVacuum')
       const buttonText = document.querySelector("h1")
       const titleVacuum = document.querySelector("#titleVacuum")
       const descriptionVacuum = document.querySelector("#descriptionVacuum")
       const lessInfoVacuum = document.querySelector('.lessInfoVacuum')
       const moreInfoVacuum = document.querySelector('.moreInfoVacuum')



       function newCenterVacuum()
        {
        controls.target.set(8,3.75,0)
        camera.far = 3.8
        camera.updateProjectionMatrix();
        backButtonVacuum.classList.add('visible')
        titleVacuum.classList.add('visible')
        descriptionVacuum.classList.add('visible')
        lessInfoVacuum.classList.add('visible')
        }


        function goBackVacuum()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(10.53,4.97,.124)
        camera.updateProjectionMatrix();
        titleVacuum.classList.remove('visible')
        descriptionVacuum.classList.remove('visible')
        lessInfoVacuum.classList.remove('visible')
        moreInfoVacuum.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX = screenPosition.x * sizes.width * 0.5
       const translateY = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX}px)  translateY(${translateY}px)`


       buttonVacuum.addEventListener('click', function() {
       newCenterVacuum()
       point.element.classList.add('inside')
       })


       backButtonVacuum.addEventListener('click', function() {
       goBackVacuum()
       point.element.classList.remove('inside')
       backButtonVacuum.classList.remove('visible')
     })

     lessInfoVacuum.addEventListener('click', function(){
        descriptionVacuum.classList.remove('visible')
        titleVacuum.classList.remove('visible')
        moreInfoVacuum.classList.add('visible')
       })

        moreInfoVacuum.addEventListener('click', function(){
        descriptionVacuum.classList.add('visible')
        titleVacuum.classList.add('visible')
        lessInfoVacuum.classList.add('visible')
        moreInfoVacuum.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick5)
}

tick5()


        vacuum.scene.scale.set(.21,.21,.21)
        vacuum.scene.position.set(8,2.9,0)
        scene.add(vacuum.scene)

        updateAllMaterials()
    }
)



gltfLoader.load(
    '/models/clock.glb',
    (clock) =>
    {  

   //Animate

   const tick6 = () =>
{

   //Update Objects
   clock.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsClock)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonClock = document.querySelector('.backButtonClock')
       const buttonClock = document.querySelector('.pointClock')
       const titleClock = document.querySelector("#titleClock")
       const descriptionClock = document.querySelector("#descriptionClock")
       const lessInfoClock = document.querySelector('.lessInfoClock')
       const moreInfoClock = document.querySelector('.moreInfoClock')



       function newCenterClock()
        {
        controls.target.set(2.5,3.4,6.7)
        camera.far = 4.55
        camera.updateProjectionMatrix();
        backButtonClock.classList.add('visible')
        titleClock.classList.add('visible')
        descriptionClock.classList.add('visible')
        lessInfoClock.classList.add('visible')
        }


        function goBackClock()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(3.613,4.97,9.88)
        camera.updateProjectionMatrix();
        titleClock.classList.remove('visible')
        descriptionClock.classList.remove('visible')
        lessInfoClock.classList.remove('visible')
        moreInfoClock.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX5 = screenPosition.x * sizes.width * 0.5
       const translateY5 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX5}px)  translateY(${translateY5}px)`


       buttonClock.addEventListener('click', function() {
       newCenterClock()
       point.element.classList.add('inside')
       })


       backButtonClock.addEventListener('click', function() {
       goBackClock()
       point.element.classList.remove('inside')
       backButtonClock.classList.remove('visible')
     })

        lessInfoClock.addEventListener('click', function(){
        descriptionClock.classList.remove('visible')
        titleClock.classList.remove('visible')
        moreInfoClock.classList.add('visible')
       })

       moreInfoClock.addEventListener('click', function(){
        descriptionClock.classList.add('visible')
        titleClock.classList.add('visible')
        lessInfoClock.classList.add('visible')
        moreInfoClock.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick6)
}

tick6()


        clock.scene.scale.set(.2,.2,.2)
        clock.scene.position.set(2.5,2.75,6.7)
        scene.add(clock.scene)

        updateAllMaterials()
    }
)




gltfLoader.load(
    '/models/switch.glb',
    (switch1) =>
    {  

   //Animate

   const tick7 = () =>
{

   //Update Objects
   switch1.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsSwitch)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonSwitch = document.querySelector('.backButtonSwitch')
       const buttonSwitch = document.querySelector('.pointSwitch')
       const titleSwitch = document.querySelector("#titleSwitch")
       const descriptionSwitch = document.querySelector("#descriptionSwitch")
       const lessInfoSwitch = document.querySelector('.lessInfoSwitch')
       const moreInfoSwitch = document.querySelector('.moreInfoSwitch')



       function newCenterSwitch()
        {
        controls.target.set(-.7,3.3,7)
        camera.far = 4.4
        camera.updateProjectionMatrix();
        backButtonSwitch.classList.add('visible')
        titleSwitch.classList.add('visible')
        descriptionSwitch.classList.add('visible')
        lessInfoSwitch.classList.add('visible')
        }


        function goBackSwitch()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(-1.03,4.97,10.48)
        camera.updateProjectionMatrix();
        titleSwitch.classList.remove('visible')
        descriptionSwitch.classList.remove('visible')
        lessInfoSwitch.classList.remove('visible')
        moreInfoSwitch.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX6 = screenPosition.x * sizes.width * 0.5
       const translateY6 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX6}px)  translateY(${translateY6}px)`


       buttonSwitch.addEventListener('click', function() {
       newCenterSwitch()
       point.element.classList.add('inside')
       })


       backButtonSwitch.addEventListener('click', function() {
       goBackSwitch()
       point.element.classList.remove('inside')
       backButtonSwitch.classList.remove('visible')
     })

     lessInfoSwitch.addEventListener('click', function(){
        descriptionSwitch.classList.remove('visible')
        titleSwitch.classList.remove('visible')
        moreInfoSwitch.classList.add('visible')
       })

       moreInfoSwitch.addEventListener('click', function(){
        descriptionSwitch.classList.add('visible')
        titleSwitch.classList.add('visible')
        lessInfoSwitch.classList.add('visible')
        moreInfoSwitch.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick7)
}

tick7()


        switch1.scene.scale.set(.28,.28,.28)
        switch1.scene.position.set(-.7,2.5,7)
        scene.add(switch1.scene)

        updateAllMaterials()
    }
)

gltfLoader.load(
    '/models/motem.glb',
    (motem) =>
    {  

   //Animate

   const tick8 = () =>
{

   //Update Objects
   motem.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsMotem)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonMotem = document.querySelector('.backButtonMotem')
       const buttonMotem = document.querySelector('.pointMotem')
       const titleMotem = document.querySelector("#titleMotem")
       const descriptionMotem = document.querySelector("#descriptionMotem")
       const lessInfoMotem = document.querySelector('.lessInfoMotem')
       const moreInfoMotem = document.querySelector('.moreInfoMotem')



       function newCenterMotem()
        {
        controls.target.set(-3.5,3.2,5.9)
        camera.far = 5.1
        camera.updateProjectionMatrix();
        backButtonMotem.classList.add('visible')
        titleMotem.classList.add('visible')
        descriptionMotem.classList.add('visible')
        lessInfoMotem.classList.add('visible')
        }


        function goBackMotem()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(-5.34,4.97,9.06)
        camera.updateProjectionMatrix();
        titleMotem.classList.remove('visible')
        descriptionMotem.classList.remove('visible')
        lessInfoMotem.classList.remove('visible')
        moreInfoMotem.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX7 = screenPosition.x * sizes.width * 0.5
       const translateY7 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX7}px)  translateY(${translateY7}px)`


       buttonMotem.addEventListener('click', function() {
       newCenterMotem()
       point.element.classList.add('inside')
       })


       backButtonMotem.addEventListener('click', function() {
       goBackMotem()
       point.element.classList.remove('inside')
       backButtonMotem.classList.remove('visible')
     })

     lessInfoMotem.addEventListener('click', function(){
        descriptionMotem.classList.remove('visible')
        titleMotem.classList.remove('visible')
        moreInfoMotem.classList.add('visible')
       })

       moreInfoMotem.addEventListener('click', function(){
        descriptionMotem.classList.add('visible')
        titleMotem.classList.add('visible')
        lessInfoMotem.classList.add('visible')
        moreInfoMotem.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick8)
}

tick8()


        motem.scene.scale.set(.14,.14,.14)
        motem.scene.position.set(-3.5,3.1,5.9)
        scene.add(motem.scene)

        updateAllMaterials()
    }
)

gltfLoader.load(
    '/models/pills.glb',
    (pills) =>
    {  

   //Animate

   const tick9 = () =>
{

   //Update Objects
   pills.scene.rotation.y += 0.005


   // Update controls
   controls.update()

   for(const point of pointsPills)
   {

       const screenPosition = point.position.clone()
       screenPosition.project(camera)


       const pointDistance = point.position.distanceTo(camera.position)
       const backButtonPills = document.querySelector('.backButtonPills')
       const buttonPills = document.querySelector('.pointPills')
       const titlePills = document.querySelector("#titlePills")
       const descriptionPills = document.querySelector("#descriptionPills")
       const lessInfoPills = document.querySelector('.lessInfoPills')
       const moreInfoPills = document.querySelector('.moreInfoPills')



       function newCenterPills()
        {
        controls.target.set(-5.9,3.2,3.7)
        camera.far = 5
        camera.updateProjectionMatrix();
        backButtonPills.classList.add('visible')
        titlePills.classList.add('visible')
        descriptionPills.classList.add('visible')
        lessInfoPills.classList.add('visible')
        }


        function goBackPills()
        {
        controls.target.set(0,0,0)
        camera.far = 6
        camera.position.set(-8.87,4.97,5.61)
        camera.updateProjectionMatrix();
        titlePills.classList.remove('visible')
        descriptionPills.classList.remove('visible')
        lessInfoPills.classList.remove('visible')
        moreInfoPills.classList.remove('visible')
        }


       if(pointDistance < 2.52) {
        point.element.classList.add('visible')
       } else
       {
        point.element.classList.remove('visible')
       }


       const translateX8 = screenPosition.x * sizes.width * 0.5
       const translateY8 = screenPosition.y * sizes.height * -0.5
       point.element.style.transform = `translateX(${translateX8}px)  translateY(${translateY8}px)`


       buttonPills.addEventListener('click', function() {
       newCenterPills()
       point.element.classList.add('inside')
       })


       backButtonPills.addEventListener('click', function() {
       goBackPills()
       point.element.classList.remove('inside')
       backButtonPills.classList.remove('visible')
     })

     lessInfoPills.addEventListener('click', function(){
        descriptionPills.classList.remove('visible')
        titlePills.classList.remove('visible')
        moreInfoPills.classList.add('visible')
       })

       moreInfoPills.addEventListener('click', function(){
        descriptionPills.classList.add('visible')
        titlePills.classList.add('visible')
        lessInfoPills.classList.add('visible')
        moreInfoPills.classList.remove('visible')
       })
       
       
   }


   // Render
   renderer.render(scene, camera)

   // Call tick again on the next frame
   window.requestAnimationFrame(tick9)
}

tick9()


        pills.scene.scale.set(.25,.25,.25)
        pills.scene.position.set(-5.9,3.3,3.7)
        scene.add(pills.scene)

        updateAllMaterials()
    }
)




/**
 * Points of Interest
 */

 const pointsVacuum = [
    {
        position: new THREE.Vector3(8.2, 4.1, .4),
        element: document.querySelector('.pointVacuum-0')
    }
]

const pointsLaundry = [
    {
        position: new THREE.Vector3(8.1, 4.1, -2.7),
        element: document.querySelector('.pointLaundry-0')
    }
]

const pointsTrash = [
    {
        position: new THREE.Vector3(5.95, 4.1, -5.7),
        element: document.querySelector('.pointTrash-0')
    }
]

const pointsPills = [
    {
        position: new THREE.Vector3(-6.9,4.1,4.5),
        element: document.querySelector('.pointPills-0')
    }
]

const pointsMotem = [
    {
        position: new THREE.Vector3(-4.5,4.1,7),
        element: document.querySelector('.pointMotem-0')
    }
]

const pointsSwitch = [
    {
        position: new THREE.Vector3(-1.3,4.1,8.2),
        element: document.querySelector('.pointSwitch-0')
    }
]

const pointsClock = [
    {
        position: new THREE.Vector3(2.4,4.1,7.9),
        element: document.querySelector('.pointClock-0')
    }
]

const pointsPhone = [
    {
        position: new THREE.Vector3(5.4,4.1,6.4),
        element: document.querySelector('.pointPhone-0')
    }
]

const pointsBlender = [
    {
        position: new THREE.Vector3(7.7,4.1,3),
        element: document.querySelector('.pointBlender-0')
    }
]

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



/**
 * DEBUG
 */

// const gui = new dat.GUI()




//Lights
// gui.add(directionalLight, 'intensity').min(0).max(10).step(0.001).name('lightIntensity')
// gui.add(directionalLight.position, 'x').min(-5).max(5).step(0.001).name('lightX')
// gui.add(directionalLight.position, 'y').min(-5).max(5).step(0.001).name('lightY')
// gui.add(directionalLight.position, 'z').min(-5).max(5).step(0.001).name('lightZ')
// gui.add(controls,'maxAzimuthAngle').min(Math.PI*-2).max(Math.PI*2).step(.01).name('maxAz')
// gui.add(controls,'minAzimuthAngle').min(Math.PI*-2).max(Math.PI*2).step(.01).name('minAz')
// gui.add(controls,'maxPolarAngle').min(Math.PI*-2).max(Math.PI*2).step(.01).name('maxPo')
// gui.add(controls,'minPolarAngle').min(Math.PI*-2).max(Math.PI*2).step(.01).name('minPo')

/**
 * Camera
 */


// Base camera
const camera = new THREE.PerspectiveCamera(44, sizes.width / sizes.height, 0.1, 6)
camera.position.set(9.68,4.97,4.076)
// camera.position.set(0,25,0)
scene.add(camera)


// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.dampingFactor = .01
controls.enablePan = false
// controls.minDistance = 1.2
// controls.maxDistance = 1.6
controls.maxPolarAngle = 1.13
controls.minPolarAngle = 1.13
controls.enableZoom = false
controls.minAzimuthAngle = -.32*(Math.PI)
controls.maxAzimuthAngle = .76*(Math.PI)




/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x000000, 0)
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding






// const parameters = {
//     newCenter: () =>
//     {
//     controls.target.set(8,3.8,0)
//     camera.far = 2.5
//     camera.updateProjectionMatrix();
//     }
// }

// const back = {
//     sendBack: () =>
//     {
//     controls.target.set(0,0,0)
//     camera.position.set(0,1,11.6)
//     camera.far = 6
//     camera.updateProjectionMatrix();
//     }
// }

// gui.add(back,'sendBack')

// gui.add(parameters,'newCenter')


const openingButton = document.querySelector('.openingButton')
const openingButton2 = document.querySelector('.openingButton2')
const openingImg = document.querySelector('.titleImg')
const openingAbout = document.querySelector('.openingAbout')
const all = document.querySelector('.all')
const opening = document.querySelector('.opening')


openingButton2.addEventListener('click', function() {
    openingImg.classList.add('blur')
    openingButton2.classList.add('blur')
    openingAbout.classList.add('blur')
})

openingButton.addEventListener('click', function(){
    openingAbout.classList.add('blur')
    // all.classList.add('unblur')
    openingAbout.classList.remove('blur')
    openingButton.classList.add('blur')
    opening.classList.add('invisible')
})


const phoneScreen = document.querySelector('.phoneScreen')


function lockScreen() {
phoneScreen.classList.add('invisible')
}


if(window.innerWidth > 800) {
    lockScreen()
}

const shopButton = document.querySelector('.shopButton')
const shop = document.querySelector('.shop')
const shopAbout = document.querySelector('.shopAbout')
const shopBack = document.querySelector('.shopBack')

shopButton.addEventListener('click', function() {
    shop.classList.add('visible')
    shopBack.classList.add('visible')
    shopAbout.classList.add('visible')
})

shopAbout.addEventListener('click', function() {
    shop.classList.remove('visible')
    shopAbout.classList.remove('visible')
})

const shopItem1 = document.querySelector('#shopitem1')
const shopItem2 = document.querySelector('#shopitem2')
const shopItem3 = document.querySelector('#shopitem3')
const shopItem4 = document.querySelector('#shopitem4')
const shopItem5 = document.querySelector('#shopitem5')
const shopItem6 = document.querySelector('#shopitem6')
const shopItem7 = document.querySelector('#shopitem7')
const shopItem8 = document.querySelector('#shopitem8')
const shopItem9 = document.querySelector('#shopitem9')
const shopItem10 = document.querySelector('#shopitem10')
const shopItem11 = document.querySelector('#shopitem11')
const shopItem12 = document.querySelector('#shopitem12')
const shopItem13 = document.querySelector('#shopitem13')
const shopItem14 = document.querySelector('#shopitem14')
const shopItem15 = document.querySelector('#shopitem15')
const shopItem16 = document.querySelector('#shopitem16')
const shopAboutText = document.querySelector('.shopAboutText')



shopItem1.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Sick of using goods and having nothing to show for it? Choose from a variety of Trash and Waste NFTs to decorate your space!'
})

shopItem1.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})

shopItem2.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Lonely? Subscribe to Infestations to never feel alone in your home again!'
})

shopItem2.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})



shopItem3.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Do you want your avatar’s clothing to become more pungent and unusable with every wear? Purchase the Laundry Rotation subscription to enable a laundry period during which worn clothing will become unavailable.'
})

shopItem3.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem4.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Are you tired of your metaverse property remaining so clean? Click here to purchase the Dirt & Dust Accumulation subscription. Your property will slowly accumulate filth for a small monthly fee. '
})

shopItem4.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem5.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Beautiful sights are plentiful these days. The Bad View NFT provides users with their own unique wall-facing window to give the impression of life in a cramped city apartment. The sound of obnoxiously loud neighbors is included for an additional fee!'
})

shopItem5.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})





shopItem6.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Feeling too comfortable in your auditory world? Purchase the Annoying Sounds subscription to hear the occasional squeaky hinge and fork against plate.'
})

shopItem6.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem7.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Gain a sense of process by disabling your ability to speak things into existence. Tediously make everything by hand for a small monthly fee. '
})

shopItem7.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem8.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Is information too readily available in your avatars viewport? With a Smartphone subscription, limit your access to information to a small rectangular panel. '
})

shopItem8.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem9.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Form bonds with those around you by sharing the same grueling experience. With the Slow Pass, join a line whenever you try to go somewhere.'
})
shopItem9.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem10.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = ' Instantaneous travel takes away from valuable rest periods. The Commute subscription reserves time for reading and listening to podcasts before arriving at destinations. '
})
shopItem10.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})





shopItem11.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Want to want your food more? Increase anticipation for meal simulations with the Meal Prep subscription.'
})

shopItem11.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem12.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Get back to the good old days with the No Internet subscription. Occasionally glitch and lag throughout the day!'
})

shopItem12.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem13.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Stand out from the crowd with the Body Odor NFT.'
})

shopItem13.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})



shopItem14.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Add unique texture to your avatars perfect skin with custom Blemish, Pimple, and Scar NFTs.'
})

shopItem14.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})



shopItem15.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Lacking a sense of continuity? Subscribe to Bathroom Break to require a restroom visit at least 8 times per day.'
})
shopItem15.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})




shopItem16.addEventListener('mouseover', function() {
    shopAboutText.innerHTML = 'Tired of being able to look exactly how you want? With the Body Dysmorphia subscription, you’ll never enjoy your appearance again! '
})

shopItem16.addEventListener('mouseout',function() {
    shopAboutText.innerHTML = 'GO BACK TO MAIN EXIBITS'
})



const ticket1 = document.querySelector('.ticketImg')
const ticket2 = document.querySelector('.ticketImg2')

ticket1.addEventListener('click', function() {
    shop.classList.remove('visible')
})

ticket2.addEventListener('click', function() {
    shop.classList.remove('visible')
})
